<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-text class="ma-0 pa-0">
        <v-form lazy-validation ref="form">
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('customers.lang_cust_prename')" :rules="[ v => !!v]"
                            @focus="showTouchKeyboard" dense outlined
                            v-model="form.editUserPreName"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('customers.lang_cust_name')" :rules="[ v => !!v]"
                            @focus="showTouchKeyboard" dense outlined
                            v-model="form.editUserLastName"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :items="this.salutations" :label="this.$t('customers.lang_cust_anrede')"
                        :rules="[ v => !!v]"
                        @focus="showTouchKeyboard"
                        dense item-text="name" item-value="id" outlined
                        v-model="form.editUserSalutation"/>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="this.$t('settings.lang_personalNo')" @focus="showTouchKeyboard"
                            dense outlined v-model="form.personalNumber"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <div>
                <v-row>
                  <v-col cols="12" md="8" sm="6" v-if="this.$store.getters['permissions/checkModule'](26)">
                    <v-text-field ref="userToken" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  @focus="showTouchKeyboard" dense :loading="loadingToken" :disabled="loadingToken"
                                  :label="$t('settings.lang_userToken')" outlined v-model="form.editUserUserToken" :rules="[tokenRules]"
                                  @blur="checkTokenDup()"
                    >
                      <template slot="append">
												<font-awesome-icon
													:icon="['fal', 'times']"
													@click="generateToken"
													class="linkCursor"
													style="font-size: 25px;"
												/>
											</template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    :sm="this.$store.getters['permissions/checkModule'](26) ? 6 : 12"
                    :md="this.$store.getters['permissions/checkModule'](26) ? 4 : 12"
                  >
                    <v-dialog
                        v-model="dialog"
                        persistent
                        max-width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="pt-1 pr-10 ma-0 colorButton"
                            :color="form.tokenColor"

                        >
                          <v-icon dark color="black">
                            mdi-eyedropper
                          </v-icon>
                          {{$t('erp.lang_warecreate_color')}}
                        </v-btn>

                      </template>
                      <v-card>
                        <v-card-title class="headline">
                          {{ $t('settings.lang_pickColor') }}
                        </v-card-title>
                        <v-card-text>
                          <div class="card-body pa-0">
                            <v-color-picker
                                v-model="form.tokenColor"
                                class="ma-0"
                                width="100%"
                                hide-canvas
                                show-swatches
                                swatches-max-height="100"
                            ></v-color-picker>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                          >
                            {{$t('generic.lang_close')}}
                          </v-btn>
                          <v-btn
                              color="blue darken-1"
                              text
                              @click="dialog = false"
                          >
                            {{$t('generic.lang_save')}}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </div>

            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.PermGroup"
                        :label="this.$t('settings.lang_userRights')"
                        :rules="[ v => !!v]"
                        @focus="showTouchKeyboard" dense item-text="name"
                        item-value="id" outlined
                        v-model="form.editUserUserPermGroup"/>
            </v-col>

            <v-col cols="12" md="4" sm="6">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('settings.lang_userIdOnDispensingSystem')" @focus="showTouchKeyboard"
                            dense outlined v-model="form.dispensingKey"/>
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <v-select :label="$t('generic.lang_overrideCashierId')" clearable
                        :items="filteredCashierIDs" item-value="value" item-text="name"
                            dense outlined v-model="form.overrideCashierId"
              />
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field :append-icon="this.visible ? 'lnr-eye' : 'lnr-eye'"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="this.$t('generic.lang_neues_passwort')"
                                :type="this.visible ? 'text' : 'password'"
                                @click:append="visible = !visible"
                                @focus="showTouchKeyboard"
                                class="input-group--focused pb-0"
                                dense
                                :hint="$t('generic.lang_atLeast5Characters')"
                                outlined v-model="form.editUserPassword" value=""/>
                  <password :strength-meter-only="true" class="ma-0" v-model="form.editUserPassword"/>

                </v-col>


                <v-col cols="12" sm="6">
                  <v-text-field :append-icon="this.visible ? 'lnr-eye' : 'lnr-eye'"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                :label="this.$t('generic.lang_neues_passwort_rep')"
                                :rules="[this.passRules.passwordMatch]"
                                :type="this.visible ? 'text' : 'password'"
                                @click:append="visible = !visible"
                                @focus="showTouchKeyboard"
                                class="input-group--focused"
                                dense
                                :hint="$t('generic.lang_atLeast5Characters')"
                                outlined v-model="form.editUserPasswordRepeat" value=""/>
                </v-col>
              </v-row>
            </v-col>


            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{ this.$t("settings.lang_userTimeRecordingActive") }}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isEmployee"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{$t('generic.lang_isServiceProvider')}}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isServiceProvider"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col class="pt-0 mt-0" cols="12" md="4" sm="6">
              <strong>{{$t('generic.lang_isDeliveryProvider')}}</strong>
              <v-row>
                <v-col>
                  <v-switch :value="1" :label="$t('generic.lang_enabled')" v-model="form.isDeliveryProvider"/>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12">
              <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
                <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                    :defaultKeySet="touchKeyboard.keySet"
                                    :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                    :options="touchKeyboard.options" class="internalWidth"
                                    id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
              </div>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "../../../../../mixins/KeyboardMixIns";
import Password from 'vue-password-strength-meter'
import {ENDPOINTS} from "@/config";
import {mapGetters} from "vuex";
import randomString from "randomstring";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faBarcode, faTimes} from "@fortawesome/pro-light-svg-icons";

library.add(faBarcode,faTimes);

export default {
  name: "PersonalInfoComponent",
  components: {
    Password,
    "font-awesome-icon": FontAwesomeIcon,
  },
  mixins: [mixin],
  props: {
    form: Object,
    PermGroup: Array,
    userId:Number,
  },
  data: () => {
    return {
      visible: false,
      dialog: false,
      tokenColor : null,
      passwordRepeat: "",
      awaitingSearch:null,
      loadingToken:false,
      dupToken:true,
    }
  },
  watch:{
    'form.editUserUserToken':function(val){
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (val && val.toString().length>0) {
          this.checkTokenDup();
        }
      }, 2000);
    },
  },
  computed: {
    ...mapGetters({
      cashierIDs:"cashierIDs/cashiers",
    }),
    filteredCashierIDs(){
      return this.cashierIDs.map(item => ({
        name:this.$t('generic.lang_kasse') +' '+ item.id + (item.aliasName ? ` (${item.aliasName}) ` : ''),
        value:item.id
      }))
    },
    salutations: function () {
      return [
        {name: this.$t("generic.lang_gender_m"), id: "m"},
        {name: this.$t("generic.lang_gender_w"), id: "w"},
      ];
    },
    passRules: function () {
      return {
        required: value => !!value || this.$t('generic.lang_requiredField'),
        min: v => v && v.length >= 5 || this.$t('generic.lang_atLeast5Characters'),
        passwordMatch: () => (this.form.editUserPassword === this.form.editUserPasswordRepeat) || (this.$t('generic.lang_passwordYouEnteredDontMatch')),
      }
    },
    tokenRules() {
      return this.dupToken || this.$t('generic.lang_tokenAlreadyTaken')
    },
  },
  mounted() {
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    checkTokenDup(){

      this.dupToken=true;
      if(!(this.form.editUserUserToken && this.form.editUserUserToken.length>0))
        return

      this.loadingToken=true;
      this.axios.post(ENDPOINTS.SETTINGS.USER.CHECKDUPTOKEN,{
        userId:this.userId,
        usertoken:this.form.editUserUserToken,
      }).then((res) => {
        this.dupToken=res.data.success;
      }).catch(err=>{

      }).finally(()=>{
        this.loadingToken=false;
      })
    },
    generateToken() {
      this.$refs.userToken.focus();

      let str = randomString.generate({
        length: 12,
        charset: 'hex',
        capitalization: 'lowercase'
      });
      var tkn = str.match(/.{1,4}/g);
      this.form.editUserUserToken = tkn.join("-");

      this.$refs.userToken.blur();
    },
  }
}
</script>

<style scoped>
.colorButton {
  height: 40px;
  max-height: 40px;
  width: 100%;


}
</style>
